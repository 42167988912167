@import "theme.scss"; @import "Base.scss";
@tailwind base;
@tailwind components;
@tailwind utilities;
// @import "../styles/theme.scss";

html,
body {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  scroll-behavior: smooth;
  background-color: var(--bgPrimary) !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:active,
input:-webkit-autofill:focus {
  background-color: #ffffff !important;
  color: #555 !important;
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
  -webkit-text-fill-color: #555555 !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input,
select {
  outline: 0;
}
// step wizard
.wizardTransition {
  transition: opacity 0.5s;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

* {
  font-family: "Poppins", sans-serif;
  // font-family: "Google Sans" !important;
  // font-family: "Manrope", sans-serif !important;
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}

.errorBoundary {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: var(--textPrimary);
  background-color: var(--bgPrimary);
  min-height: 100vh;
}
.CustomerEditmodal {
  width: 30% !important;
  &:focus-visible {
    outline: none;
  }
}

.loaderMain {
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  left: 0;
  z-index: 100;
  &::before {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    width: 100%;
    height: 100%;
    filter: blur(10px);
    background-color: rgba(0, 0, 0, 0.2);
  }
  div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
  }
}

// custom slick design

.slick-dots li {
  margin: 0 3px !important;
}

.slick-dots li button:before {
  font-size: 10px !important;
}
.slick-dots {
  position: unset !important;
  margin-top: 25px !important;
}
.errorMSG {
  color: tomato;
}
.desktoperror {
  display: block;
  @media (max-width: "991px") {
    display: none;
  }
}
.mobileerror {
  display: none;
  @media (max-width: "991px") {
    display: block;
  }
}

// custom wrap

.customCheckbox {
  position: relative;
  width: 24px;
  height: 24px;
  background: white;
  border-radius: 2px;
  input {
    position: absolute;
    opacity: 0;
    z-index: 3;
  }
  svg {
    position: absolute;
    display: none;
    top: 8px;
    right: 30%;
  }

  input[type="checkbox"]:checked + svg {
    display: block;
  }
}
.modalContainer {
  background: #ffffff;
  box-shadow: -1px 5px 30px rgba(0, 0, 0, 0.13);
  border-radius: 22px;
}
.preveImg {
  width: 40%;
  margin: 0 auto;
}
.hideinput {
  visibility: hidden;
}
// top nav
.szh-menu {
  // width: 250px !important;
  // border-radius: 20px !important;
  // margin-top: 6px !important;
  // background: #FFFFFF !important;
  //   border: 2px solid #F5F3F8 !important;
}
.otpContrainer {
  div {
    div {
      margin-right: 10px;
      input {
        width: 42px !important;
        height: 42px;
        border-radius: 7px;
        &:focus {
          outline: none;
        }
      }
    }
  }
  span {
    display: none;
  }
}

// customer
#tab {
  .react-tabs__tab.react-tabs__tab--selected {
    padding-bottom: 7px;
    border-bottom: 2px solid #02aa45;
    z-index: 5;
    outline: none;
  }
}
#CardTab {
  display: flex;
  justify-content: center;
  .react-tabs__tab.react-tabs__tab--selected {
    padding-bottom: 7px;
    background-color: var(--bgSecondary);
    border: 0 !important;
    color: var(--textPrimary);
    z-index: 5;
    border-radius: 30px;
    outline: none;
  }
  ul {
    display: flex;
    align-items: center;
    &::after {
      border-bottom: none;
    }
    li {
      cursor: pointer;
      padding: 8px 16px;
      margin-right: 15px;
      font-weight: 500;
      font-size: 16px;
      color: var(--textPrimary);
      white-space: nowrap;
      @media (max-width: 513px) {
        font-size: 10px;
        margin-right: 10px;
      }
      @media (max-width: 395px) {
        font-size: 8px;
        margin-right: 2px;
      }
    }
  }
}
// map customization

.pac-container {
  border: 2px solid #f5f3f8 !important;
  border-radius: 11px;
  box-shadow: rgb(0 0 0 / 10%) 0px 10px 15px -3px,
    rgb(0 0 0 / 5%) 0px 4px 6px -2px;
  width: 460px !important;
  .pac-item {
    padding: 4px 4px !important;
  }
}

.pac-logo:after {
  background-image: none !important;
}

.hideP {
  display: none;
}

// custom validator for modal

.errorMessage {
  font-size: 12px;
  color: tomato;
  margin-left: 10px;
}

// table
#table {
  overflow-x: auto;
  table {
    width: 100%;
    min-width: 800px;
    input {
      width: 20px;
      height: 20px;
      background: #5f5fc1;
      border: 1px solid #5f5fc1;
      border-radius: 2px;
    }
    .thead {
      th {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        color: var(--textPrimary);
        border-top: 1px solid var(--borderPrimary);
        border-bottom: 1px solid var(--borderPrimary);
        padding: 20px 0;
      }
    }

    .tbody {
      tr {
        td {
          border-top: 1px solid var(--borderPrimary);
          border-bottom: 1px solid var(--borderPrimary);

          text-align: center;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: var(--textSecondary);
          padding: 20px 0;

          .actions {
            display: flex;
            align-items: center;
            margin: 0 auto;
            width: fit-content;

            svg {
              cursor: pointer;
              &:first-child {
                margin-right: 26px;
                path {
                  fill: var(--textPrimary);
                }
              }
            }
          }
        }
      }
    }
  }
}

.ReactModal__Overlay {
  z-index: 10;
  background-color: rgb(0, 0, 0, 0.4) !important;
  .ReactModal__Content {
    width: 50%;
    padding: 40px 20px 20px !important;
    border-radius: 10px !important;
    background-color: var(--bgPrimary) !important;
    @media (max-width: 900px) {
      width: 90%;
    }
    position: relative;
    border: none !important;
    .Close {
      position: absolute;
      right: 10px;
      top: 10px;
    }
    img {
      margin: 0 auto;
    }
    .modalHead {
      display: flex;
      align-items: center;
      margin-bottom: 80px;
      span {
        color: var(--borderPrimary);
        font-weight: 300;
        font-size: 20px;
      }
      .ModalHeadText {
        flex-grow: 1;
        text-align: center;
        h3 {
          color: var(--textPrimary);
          font-weight: 700;
          font-size: 16px;
        }
        p {
          font-weight: 400;
          font-size: 14px;
          color: var(--textPrimary);
        }
      }
    }
    .ModalFooter {
      display: flex;
      margin-top: 50px;
      .SkipContainer {
        flex-grow: 1;
        button {
          background: var(--bgSecondary);
          border-radius: 6px;
          color: var(--textPrimary);
          padding: 9px 48px;
          @media (max-width: 500px) {
            padding: 5px 25px;
          }
        }
      }
      .prevNextContianer {
        .prev {
          border: 1px solid #c4c4c4;
          border-radius: 6px;
          padding: 9px 48px;
          color: var(--textPrimary);

          margin-right: 10px;
          @media (max-width: 500px) {
            padding: 5px 25px;
          }
        }
        .next {
          background: #e81a6e;
          border-radius: 6px;
          padding: 9px 48px;
          color: #ffffff;
          @media (max-width: 500px) {
            padding: 5px 25px;
          }
        }
      }
    }
  }
}

// menu
.szh-menu-container {
  width: unset !important;
}

// impotrant

#fAddress {
  position: relative !important;
  z-index: -3 !important;
  display: none;
}
